/* ==============================
   Global Variables
   ============================== */
:root {
  --primary-color: #3366cc;
  --secondary-color: white;
  --text-dark: #333;
  --text-light: #f4f4f4;
  --bg-light: #f8f9fc;
  --font-primary: 'Nunito', sans-serif;
  --font-size-base: 16px;
  --font-size-heading: 20px;
}

/* ==============================
   Global Styles
   ============================== */
body {
  font-family: var(--font-primary);
  color: var(--text-dark);
  background-color: var(--bg-light);
  font-size: var(--font-size-base);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
  color: var(--primary-color);
  font-size: var(--font-size-heading);
}
/* ==============================
   Navbar Fix: Align Items & Reduce Logo Size
   ============================== */
.navbar {
  font-size: var(--font-size-heading);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 60px;
  padding: 0 20px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px 0;
}

.navbar-brand img {
  height: 28px;
  width: auto;
  object-fit: contain;
}

.navbar .nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  list-style: none;
  height: 100%;
}

.navbar .nav li {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar .nav li a {
  color: var(--primary-color) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px !important;
  text-decoration: none;
  background: none !important;
  border: none;
  transition: color 0.3s ease;
}

.navbar .nav li a:hover {
  color: #224499 !important; /* Slightly darker blue */
  background: none !important; /* Ensure no grey box */
  border: none !important; /* Remove any outline */
  box-shadow: none !important; /* Remove shadow effects */
}

/* ==============================
   Fix Mobile Icon (Keep it Blue)
   ============================== */
.navbar-toggle {
  background: none;
  border: none;
  color: var(--primary-color) !important; /* Ensure it's blue */
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.navbar-toggle i {
  color: var(--primary-color) !important; /* Force blue icon */
}

.navbar-toggle:focus {
  outline: none;
}

/* ==============================
   Ensure Mobile Menu Works
   ============================== */
@media (max-width: 992px) {
  .navbar {
    height: 60px; /* Keep fixed height for mobile */
    padding: 0 15px;
  }

  .navbar-toggle {
    display: block !important;
    padding: 10px;
    margin-right: 0;
    z-index: 1000;
  }

  .navbar-collapse {
    display: none;
    position: fixed;
    top: 60px; /* Match navbar height */
    left: 0;
    right: 0;
    background: var(--secondary-color);
    padding: 20px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .navbar-collapse.show {
    display: flex !important;
    animation: slideDown 0.3s ease-out;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .navbar .nav {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .navbar .nav li {
    width: 100%;
    border-bottom: 1px solid rgba(51, 102, 204, 0.1);
  }

  .navbar .nav li:last-child {
    border-bottom: none;
  }

  .navbar .nav li a {
    padding: 15px 20px !important;
    width: 100%;
    justify-content: center;
    font-size: 16px !important;
  }
}

/* ==============================
   Hero Section
   ============================== */
.hero-container {
  position: relative;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.hero {
  flex: 1;
  padding: 80px 0;
  display: flex;
  align-items: center;
  background-image: url('/public/assets/img/bg-1.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.hero-illustration {
  position: relative;
  z-index: 2;
}

.carousel-section {
  height: 120px;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.skill-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 5px;
}

.skill-label {
  color: var(--text-dark);
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.feature-single-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
}

@media (max-width: 768px) {
  .hero {
    padding: 60px 0;
    text-align: center;
  }

  .carousel-section {
    height: 100px;
    padding: 10px 0;
  }

  .skill-icon {
    width: 35px;
    height: 35px;
    margin-bottom: 3px;
  }

  .skill-label {
    font-size: 12px;
  }

  .feature-single-item {
    padding: 3px;
  }
}

@media (max-width: 768px) {
  .hero img {
    display: none;
  }
}

/* Carousel Section - Resizing to 10% of Hero Section */
.carousel-section {
  padding: 15px;
  background-color: white;
  text-align: left;
}

/* Container for Carousel Icons */
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.full-features-area {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center;
  height: 100px; /* Adjust if necessary */
  padding: 10px 0; /* Reduce unnecessary padding */
}

@media (max-width: 768px) {
  .carousel-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px; /* Ensure enough spacing below */
    position: relative;
  }
}

.full-features-area .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Critical for vertical centering */
  gap: 10px; /* Adjust spacing between icons */
}

.skill-icon {
  width: 60px;  /* Adjust icon size */
  height: auto;
}
.skill-thumb img {
  width: 60px;  /* Keep skill icons small */
  height: auto;
  object-fit: contain;
}

.skill-label {
  text-align: left;
  margin-top: 5px; /* Ensure spacing between icon and text */
}

@media (max-width: 768px) {
  .feature-single-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  .thumb img {
    width: 60px;
    height: auto;
}

  .skill-label {
      font-size: 12px;
      text-align: left;
  }
}

.feature-single-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Ensures perfect vertical centering */
  height: 100%; /* Takes full height of parent container */
}

/* ==============================
   About Me Section
   ============================== */

.about-container {
  position: relative;
  padding: 120px 20px 60px; /* Increased top padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clear: both;
  min-height: auto;
  box-sizing: border-box;
  margin-top: 0; /* Remove margin-top */
  background: var(--bg-light);
}

/* Left Side - Text Content */
.about-text {
  flex: 1;
  max-width: 500px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-text h2 {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.about-text p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-dark);
}

.about-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  padding: 15px;
}

.about-img {
  width: 100%;
  max-width: 450px;
  height: auto;
  object-fit: contain;
}

/* Bullet List Styling */
.about-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.about-list li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 18px;
}

.about-list .title-container {
  display: flex;
  align-items: left;
  gap: 8px;
  font-weight: 800;
  color: var(--primary-color);
}

.about-list .title-container::before {
  content: "✔";
  font-size: 22px;
  font-weight: 700;
  color: var(--primary-color);
}

.about-list h5 {
  font-size: 20px;
  font-weight: 800;
  color: var(--primary-color);
  margin: 0;
}

.about-list p {
  font-size: 18px;
  color: var(--text-dark);
  margin: 0;
  max-width: 400px;
}

.blue-bold {
  font-weight: 800;
  color: var(--primary-color);
}

.blue-underline {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  font-weight: 600;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-container {
    padding: 60px 15px; /* Match padding with Skills */
    min-height: auto;
  }

  .about-text {
    max-width: 100%;
    text-align: left; /* Align text to left like Skills section */
  }

  .about-img-container {
    max-width: 100%;
    padding: 10px;
  }

  .about-img {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    text-align: center;
    padding: 60px 15px;
    margin-top: 0 !important; /* Remove margin */
  }

  .about-img-container {
    order: -1;
    padding: 10px;
  }

  .about-img {
    width: 100%;
    max-width: 300px;
  }

  .about-list {
    grid-template-columns: 1fr;
    text-align: left; /* Match Skills section alignment */
  }
}

@media (max-width: 480px) {
  .about-container {
    padding: 50px 15px;
  }

  .about-img-container {
    padding: 5px;
  }

  .about-img {
    width: 100%;
    max-width: 250px;
  }

  .about-text {
    text-align: left; /* Ensure consistency */
  }

  .about-text p {
    font-size: 14px;
  }
}

  
/* ==============================
   Skills & Services - Fix Image Size & Spacing
   ============================== */

   .skills-container {
    min-height: 100vh;
    background-color: white;
    padding: 80px 0 20px 0;
    display: flex;
    flex-direction: column;
  }
  
  .skills-container .container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .skills-container .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 40px;
  }
  
  /* Text Container - Left Side */
  .skills-container .text-wrapper {
    flex: 1;
    max-width: 500px;
    margin-bottom: 30px;
  }
  
  .skills-container .text-wrapper p {
    font-size: 18px;
    line-height: 1.6;
    color: var(--text-dark);
    margin-top: 15px;
    text-align: left;
  }
  
  /* Image Container - Right Side */
  .skills-container .image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .skills-image {
    max-width: 80%;
    height: auto;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .service-box {
    background: linear-gradient(135deg, #3366cc, #5a85e0);
    border-radius: 10px;
    padding: 10px;
    color: white;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    min-height: 65px;
  }

  .service-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(51, 102, 204, 0.2);
  }

  .service-box .text-content {
    flex: 1;
  }

  .service-box i {
    font-size: 28px;
    color: white;
    opacity: 0.9;
  }

  .service-box h3 {
    font-size: 16px;
    font-weight: 700;
    color: white;
    margin: 0 0 3px 0;
  }

  .service-box p {
    font-size: 13px;
    line-height: 1.2;
    margin: 0;
    color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: 992px) {
    .skills-container .row {
      gap: 20px;
    }

    .skills-image {
      max-width: 70%;
    }

    .services-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .skills-container {
      padding: 60px 15px 30px;
      min-height: auto;
    }

    .skills-container .row {
      flex-direction: column;
      gap: 20px;
    }

    .skills-container .text-wrapper {
      max-width: 100%;
      margin-bottom: 0;
    }

    .skills-container .text-wrapper p {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    .skills-container .image-wrapper {
      display: none; /* Hide image on mobile */
    }

    .skills-image {
      max-width: 70%;
    }

    .services-grid {
      grid-template-columns: 1fr;
      gap: 15px;
      padding: 0 15px;
      margin-top: 20px;
    }

    .service-box {
      padding: 20px;
      min-height: auto;
    }

    .service-box h3 {
      font-size: 1.1rem;
      margin-bottom: 8px;
    }

    .service-box p {
      font-size: 0.95rem;
      line-height: 1.5;
    }
  }

  @media (max-width: 480px) {
    .services-grid {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  
/* ==============================
   Contact Section
   ============================== */

.contact-section {
  min-height: 100vh;
  background: white;
  padding: 80px 0;
  display: flex;
  align-items: center;
}

.contact-section .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-form {
  max-width: 800px;
  margin: 40px auto 0;
}

.contact-form .form-group {
  margin-bottom: 25px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  width: 100%;
  background-color: #f8f9fa;
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form .invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.contact-form .alert {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
}

.contact-form .alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.contact-form .alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 60px 20px;
  }

  .contact-form {
    margin-top: 30px;
  }

  .contact-form .form-group {
    margin-bottom: 20px;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 12px;
  }
}

/* ==============================
   Button Formatting
   ============================== */
   .btn-theme {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    padding: 12px 35px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 30px;
    text-transform: uppercase;
    font-family: var(--font-primary);
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    min-width: 180px;
    text-decoration: none;
    position: relative;
  }
  
  .btn-theme i {
    font-size: 1.2em;
    transition: transform 0.3s ease;
  }
  
  .btn-theme:hover i {
    transform: translateX(4px);
  }
  
  .btn-theme:hover,
  .btn-theme:focus {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-color: var(--secondary-color);
    text-decoration: none;
  }
  
  /* Contact form button specific styles */
  .contact-form .btn-theme {
    border-color: var(--primary-color);
  }

  .contact-form .btn-theme:hover,
  .contact-form .btn-theme:focus {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  @media (max-width: 768px) {
    .btn-theme {
      padding: 10px 25px;
      font-size: 14px;
      min-width: 160px;
      gap: 10px;
    }

    .btn-theme i {
      font-size: 0.85em;
    }
  }
  

/* ==============================
   Section Headers & Subtitles
   ============================== */
   .section-heading-left,
   .section-heading-center {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-color);
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
  
  /* Ensure subtitle is black */
  .section-subtitle-left,
  .section-subtitle-center {
    font-size: 24px;
    font-weight: 400;
    color: var(--text-dark) !important;
    margin-bottom: 20px;
  }
  .section-heading-center {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .section-subtitle-center {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-dark);
    text-align: center;
    margin-bottom: 2.5rem;
  }
  
  .section-heading-left {
    text-align: left;
  }
  
  .section-subtitle-left {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-dark);
    text-align: left;
    margin-bottom: 2rem;
  }
  
/* ==============================
   Portfolio Section
   ============================== */
   .projects-area {
    padding: 100px 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-light);
    position: relative;
  }
  
  .project-items-area {
    gap: 40px;
    padding: 0 20px;
    margin-top: 40px;
  }
  
  .project-item {
    width: 100%;
    height: 100%;
  }
  
  .project-card {
    display: block;
    text-decoration: none !important;
    height: 120%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(51, 102, 204, 0.1);
  text-decoration: none !important;
}

.project-card:hover .project-title,
.project-card:hover .project-desc {
  text-decoration: none !important;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-thumb {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.project-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-thumb img {
  transform: scale(1.05);
}

.card-body {
  padding: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.project-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: var(--primary-color);
  text-align: left;
  text-decoration: none !important;
}

.project-desc {
  font-size: 1.3rem;
  color: var(--text-dark);
  line-height: 1.8;
  margin-bottom: 0;
  text-align: left;
  text-decoration: none !important;
}

@media (max-width: 992px) {
  .project-thumb {
    height: 250px;
  }

  .card-body {
    padding: 40px;
  }

  .project-title {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }

  .project-desc {
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

@media (max-width: 768px) {
  .project-thumb {
    height: 220px;
  }

  .card-body {
    padding: 25px;
  }

  .project-title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .project-desc {
    font-size: 1rem;
    line-height: 1.6;
  }
}

@media (max-width: 480px) {
  .project-thumb {
    height: 200px;
  }

  .card-body {
    padding: 20px;
  }
}

  
  
  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto; /* Prevents unnecessary stretching */
}

.thumb img {
    width: 60px; /* Adjust to preferred size */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure full image is visible */
}

  
  
  .open-link-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .thumb:hover .open-link-icon {
    opacity: 1;
    visibility: visible;
  }
  
  .project-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-dark);
    margin-bottom: 6px;
  }
  
  .project-desc {
    font-size: 14px;
    color: var(--text-dark);
    line-height: 1.3;
  }
  .project-thumb {
    height: 130px;
    position: relative;
    overflow: hidden;
}

.project-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the space */
    aspect-ratio: 1 / 1; /* Ensures a perfect square */
}

@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .project-items-area {
    grid-template-columns: 1fr;
  }

  .service-box {
    min-height: 65px;
  }
}

@media (max-width: 768px) {
  .project-items-area .row {
      display: flex;
      flex-direction: column;
      align-items: left;
  }

  .project-item {
      width: 90%; /* Ensures full width */
      max-width: 500px; /* Keeps it readable */
  }

  .project-thumb {
      height: 120px; /* Reduce size slightly for smaller screens */
  }
}


 /* General Section Styling */
.section {
  min-height: 100vh;
  padding: 60px 20px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Navbar Offset Fix */
.header-space {
  margin-top: 60px; /* Adjust for navbar height */
}
p {
  font-size: 20px; /* Bigger paragraphs site-wide */
  line-height: 1.6;
  text-align: left;
}
.navbar .nav li a {
  padding: 20px 10px !important; /* Reducing top and bottom padding */
}
.navbar-brand img {
  max-height: 30px;
  vertical-align: middle;
}
/* ==============================
   Footer
   ============================== */
   .footer-area {
    background-color: var(--primary-color);
    color: white;
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  
  /* Set the footer container width to match the contact section */
  .footer-content {
    display: flex;
    flex-direction: row; /* Default: Side by side */
    align-items: center;
    justify-content: space-between; /* 🚀 Push icon to right */
    max-width: 900px; /* Match the contact form width */
    margin: 0 auto;
    text-align: center;
  }
  
  /* Footer Text (Centered) */
  .footer-text {
    flex: 1;
    text-align: center;
  }
  
  /* LinkedIn Icon (Right-Aligned) */
  .footer-icon {
    display: flex;
    justify-content: flex-end; /* 🚀 Push to right */
    align-items: center;
    width: 50px; /* Keeps it positioned correctly */
  }
  
  /* Ensure LinkedIn Icon Background Stays White */
  .linkedin-icon {
    font-size: 32px;
    color: white; /* Default: White icon */
    background: transparent; /* No background by default */
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%; /* Make circular */
  }
  
  /* Ensure Inner Icon is Transparent */
  .linkedin-icon i {
    background: transparent !important; /* Prevents any inherited square */
    display: flex;
  }
  
  /* Keep Background White on Mobile */
  @media (max-width: 768px) {
    .linkedin-icon {
      color: white; /* Default color */
      background: transparent; /* No background */
    }
  }
  
  /* Reverse Colors on Hover & Fix Background Issue */
  .linkedin-icon:hover {
    color: var(--primary-color); /* LinkedIn blue */
    background-color: white; /* White background */
    border-radius: 50%; /* Make circular */
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Prevent Any Focus or Active Effects */
  .linkedin-icon:focus,
  .linkedin-icon:active {
    color: white; /* Default color */
    background: transparent; /* No background */
  }
  
  /* 🚀 Mobile: Stack Icon Above Text */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column-reverse; /* 🚀 Swap positions */
      justify-content: center;
      align-items: center;
      gap: 10px;
      max-width: 90%; /* Adjust width for responsiveness */
    }
  
    .footer-icon {
      justify-content: center; /* Center icon */
      width: auto; /* Reset width */
      margin-bottom: 10px; /* Space between icon & text */
    }
  }
  
.section {
  padding-top: 80px; /* Add space above sections */
}

/* Error Boundary Styles */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
}

.error-container h1 {
  color: #dc3545;
  margin-bottom: 1rem;
}

.error-container button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.error-container button:hover {
  background-color: #0056b3;
}

/* Form Validation Styles */
.form-control.is-invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linecap='round' d='M6 3.75v1.5m0 4.5h1.5m-1.5 0l1.5-1.5'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

/* Loading State Styles */
.btn-theme:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-theme[aria-busy="true"] {
  position: relative;
  padding-right: 2.5rem;
}

.btn-theme[aria-busy="true"]::after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

/* Footer LinkedIn Icon Styles */
.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.linkedin-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent; /* Remove background color */
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.linkedin-icon:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  background-color: white; /* White background on hover */
  color: var(--primary-color); /* Primary color text on hover */
}

.linkedin-icon:focus {
  outline: 2px solid white;
  outline-offset: 2px;
}

.linkedin-icon i {
  font-size: 1.5rem;
}

/* Screen reader only text */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-icon {
    margin-top: 0;
  }
}

/* Mobile viewport adjustments */
@media (max-width: 768px) {
  .about-container {
    min-height: auto;
    padding: 60px 15px;
  }

  .skills-container {
    min-height: auto;
    padding: 60px 15px;
  }

  .contact-footer-container {
    min-height: 100vh;
  }

  /* Adjust content spacing */
  .section {
    padding: 60px 15px;
    min-height: auto;
  }

  /* Ensure proper content spacing */
  .hero-content {
    padding: 20px 0;
  }

  /* Adjust text sizes for better mobile viewing */
  .hero h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .hero h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  /* Adjust section spacing */
  .about-container,
  .skills-container,
  .contact-footer-container {
    padding: 50px 15px;
  }

  .section-heading-center {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }

  .section-subtitle-center {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-align: center !important;
  }

  .about-text,
  .about-text p,
  .about-list h5,
  .about-list p {
    font-size: 1rem;
  }
}

/* Small mobile adjustments */
@media (max-width: 480px) {
  .hero {
    padding: 30px 15px;
  }

  .section {
    padding: 50px 15px;
  }

  .hero h1 {
    font-size: 28px;
  }

  .hero h2 {
    font-size: 20px;
  }

  /* Adjust section spacing */
  .about-container,
  .skills-container,
  .contact-footer-container {
    padding: 50px 15px;
  }
}

/* Fix iOS viewport height issue */
@supports (-webkit-touch-callout: none) {
  .hero,
  .section {
    min-height: -webkit-fill-available;
  }
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 0 15px;
  }

  .service-box {
    padding: 25px 20px;
    min-height: auto;
  }

  .service-box h3 {
    font-size: 18px;
  }

  .service-box p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

.about-text {
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 25px 0;
  color: var(--text-dark);
}

.about-text p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.role-text {
  display: inline-block;
  width: 100%;
}

.no-break {
  display: inline;
  white-space: nowrap;
  color: var(--primary-color);
  font-weight: 800;
}

.about-list {
  text-align: left;
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}

.about-list li {
  margin-bottom: 1.5rem;
}

.about-list h5 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.about-list p {
  font-size: 1.1rem;
  color: var(--text-dark);
  line-height: 1.6;
  margin-top: 0.5rem;
}

.about-text strong {
  font-weight: 800;
  color: var(--primary-color);
}

.title-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title-container i {
  color: var(--primary-color);
  font-size: 1.2rem;
}

.title-container h5 {
  margin: 0;
  display: inline-block;
}

/* Skills Section */
.skills-container {
  min-height: 100vh;
  background-color: white;
  padding: 80px 0 20px 0;
  display: flex;
  flex-direction: column;
}

.skills-container .container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.skills-container .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 40px;
}

/* Text Container - Left Side */
.skills-container .text-wrapper {
  flex: 1;
  max-width: 500px;
  margin-bottom: 30px;
}

.skills-container .text-wrapper p {
  font-size: 18px;
  line-height: 1.6;
  color: var(--text-dark);
  margin-top: 15px;
  text-align: left;
}

/* Image Container - Right Side */
.skills-container .image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-image {
  max-width: 80%;
  height: auto;
}

/* Services Grid - Bottom */
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.service-box {
  background: linear-gradient(135deg, #3366cc, #5a85e0);
  border-radius: 10px;
  padding: 10px;
  color: white;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  min-height: 65px;
}

.service-box h3 {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin: 0 0 3px 0;
}

.service-box p {
  font-size: 13px;
  line-height: 1.2;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

/* Portfolio Section */
.project-items-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  margin-top: 20px;
}

.project-item {
  flex: 1 1 300px;
  max-width: 450px;
  padding: 10px;
}

.project-thumb {
  height: 300px;
  position: relative;
  overflow: hidden;
}

/* Contact + Footer Combined Section */
.contact-footer-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  margin-top: -1px;
}

.contact-area {
  flex: 1;
  width: 100%;
  padding: 80px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

.contact-form {
  max-width: 600px;
  width: 100%;
  margin: 20px auto 0;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.footer-area {
  width: 100%;
  padding: 20px;
  background-color: var(--primary-color);
  margin-top: auto;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
  .contact-footer-container {
    min-height: 100vh;
  }

  .contact-area {
    padding: 60px 15px 30px;
  }

  .contact-form {
    margin-top: 15px;
    padding: 0 15px;
  }

  .footer-area {
    padding: 15px;
  }

  .footer-content {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

/* Global Mobile Text Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 32px !important;
    line-height: 1.3;
  }

  h2 {
    font-size: 24px !important;
    line-height: 1.3;
  }

  h4.section-heading-center,
  h4.section-heading-left {
    font-size: 24px !important;
    margin-bottom: 10px;
  }

  h2.section-subtitle-center,
  h2.section-subtitle-left {
    font-size: 20px !important;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px !important;
    line-height: 1.6;
  }
}

/* Consistent Section Padding */
@media (max-width: 768px) {
  .section,
  .about-container,
  .skills-container,
  .projects-area,
  .contact-area {
    padding: 60px 20px !important;
  }

  .hero {
    padding: 40px 20px !important;
  }

  .carousel-section {
    padding: 20px 15px !important;
    height: auto !important;
  }
}

/* Button Consistency */
.btn-theme {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  padding: 12px 35px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: var(--font-primary);
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-width: 180px;
  text-decoration: none;
  position: relative;
}

.btn-theme i {
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.btn-theme:hover i {
  transform: translateX(4px);
}

.btn-theme:hover,
.btn-theme:focus {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--secondary-color);
  text-decoration: none;
}

/* Contact form button specific styles */
.contact-form .btn-theme {
  border-color: var(--primary-color);
}

.contact-form .btn-theme:hover,
.contact-form .btn-theme:focus {
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

@media (max-width: 768px) {
  .btn-theme {
    padding: 10px 25px;
    font-size: 14px;
    min-width: 160px;
    gap: 10px;
  }

  .btn-theme i {
    font-size: 0.85em;
  }
}

/* Form Elements Consistency */
@media (max-width: 768px) {
  .contact-form input,
  .contact-form textarea {
    padding: 12px;
    font-size: 14px !important;
    margin-bottom: 15px;
  }

  .form-group {
    margin-bottom: 15px !important;
  }

  .invalid-feedback {
    font-size: 12px;
    margin-top: 4px;
  }
}

/* Project Cards Mobile Consistency */
@media (max-width: 768px) {
  .project-items-area {
    padding: 0 15px;
    gap: 20px;
  }

  .project-card {
    height: auto;
  }

  .project-thumb {
    height: 200px;
  }

  .card-body {
    padding: 15px;
  }

  .project-title {
    font-size: 18px !important;
    margin-bottom: 8px;
  }

  .project-desc {
    font-size: 14px !important;
    line-height: 1.5;
  }
}

/* Skills Grid Mobile Consistency */
@media (max-width: 768px) {
  .services-grid {
    gap: 15px;
    padding: 0 15px;
  }

  .service-box {
    padding: 15px;
    min-height: auto;
  }

  .service-box h3 {
    font-size: 16px !important;
  }

  .service-box p {
    font-size: 14px !important;
  }
}

/* Footer Mobile Consistency */
@media (max-width: 768px) {
  .footer-area {
    padding: 20px 15px;
  }

  .footer-content {
    gap: 15px;
  }

  .footer-text p {
    font-size: 14px !important;
    margin: 0;
  }
}

/* Container Widths Consistency */
@media (max-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Fix iOS Viewport Height */
@supports (-webkit-touch-callout: none) {
  .hero-container,
  .about-container,
  .skills-container,
  .projects-area,
  .contact-footer-container {
    min-height: -webkit-fill-available;
  }
}

.copyright-text {
  color: white;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column-reverse;
    gap: 15px;
  }

  .copyright-text {
    font-size: 14px;
  }
}

/* Mobile Optimization */
@media (max-width: 768px) {
  /* Skills Section Mobile */
  .skills-container {
    padding: 60px 15px 30px;
    min-height: auto;
  }

  .skills-container .row {
    flex-direction: column;
    gap: 20px;
  }

  .skills-container .text-wrapper {
    max-width: 100%;
    margin-bottom: 0;
  }

  .skills-container .text-wrapper p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .skills-container .image-wrapper {
    display: none; /* Hide image on mobile */
  }

  /* Services Grid Mobile */
  .services-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 15px;
    margin-top: 20px;
  }

  .service-box {
    padding: 20px;
    min-height: auto;
  }

  .service-box h3 {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }

  .service-box p {
    font-size: 0.95rem;
    line-height: 1.5;
  }

  /* Portfolio Section Mobile */
  .projects-area {
    padding: 60px 15px 30px;
  }

  .project-items-area {
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 15px;
  }

  .project-thumb {
    height: 220px;
  }

  .card-body {
    padding: 25px;
  }

  .project-title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .project-desc {
    font-size: 1rem;
    line-height: 1.6;
  }

  /* Section Headers Mobile */
  .section-heading-center,
  .section-heading-left {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }

  .section-subtitle-center,
  .section-subtitle-left {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  /* General Text Mobile */
  p {
    font-size: 1rem !important;
    line-height: 1.6;
  }

  /* Spacing Mobile */
  .section {
    padding: 60px 15px;
    min-height: auto;
  }

  .container {
    padding: 0 15px;
  }
}

/* Small Mobile Optimization */
@media (max-width: 480px) {
  .project-thumb {
    height: 200px;
  }

  .card-body {
    padding: 20px;
  }

  .project-title {
    font-size: 1.3rem;
  }

  .project-desc {
    font-size: 0.95rem;
  }

  .service-box {
    padding: 15px;
  }

  .service-box h3 {
    font-size: 1rem;
  }

  .service-box p {
    font-size: 0.9rem;
  }

  .section-heading-center,
  .section-heading-left {
    font-size: 1.5rem;
  }

  .section-subtitle-center,
  .section-subtitle-left {
    font-size: 1.1rem;
  }
}

/* Mobile Text Alignment */
@media (max-width: 768px) {
  /* Headers */
  .section-heading-center,
  .section-heading-left,
  .section-subtitle-center,
  .section-subtitle-left {
    text-align: left !important;
  }

  /* General Text */
  p, h1, h2, h3, h4, h5, h6 {
    text-align: left !important;
  }

  /* About Section */
  .about-container,
  .about-text,
  .about-list {
    text-align: left !important;
  }

  /* Skills Section */
  .skills-container .text-wrapper,
  .skills-container .text-wrapper p,
  .service-box,
  .service-box h3,
  .service-box p {
    text-align: left !important;
  }

  /* Portfolio Section */
  .project-title,
  .project-desc,
  .card-body {
    text-align: left !important;
  }

  /* Contact Section */
  .contact-section h2,
  .contact-section p {
    text-align: left !important;
  }

  /* Footer Exception - Keep Centered */
  .footer-area,
  .footer-content,
  .footer-text,
  .copyright-text {
    text-align: center !important;
  }
}

.navbar-nav a i {
  font-size: 0.8em;
  margin-left: 5px;
  opacity: 0;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.navbar-nav a:hover i {
  opacity: 1;
  transform: translateX(2px);
}

/* Mobile menu arrow adjustments */
@media (max-width: 768px) {
  .navbar-nav a i {
    opacity: 1;
    transform: none;
    font-size: 0.9em;
  }
}